import { ReactNode } from 'react';
import Link from 'next/link';
import { ArrowRightIcon } from '@radix-ui/react-icons';
import { Slot } from '@radix-ui/react-slot';

import { Button } from '@/shared/components/ui/button';
import { cn } from '@/shared/lib/cn';

import { GridBgAnimation } from '../animation/grid-background';

interface BentoGridProps {
  children: ReactNode;
  className?: string;
}

const BentoGrid = (props: BentoGridProps) => {
  const { children, className } = props;

  return (
    <div className={cn('grid w-full auto-rows-auto grid-cols-3 gap-4 lg:auto-rows-[15rem]', className)}>{children}</div>
  );
};

interface BentoCardProps {
  name: string;
  className: string;
  Icon?: any;
  description: string;
  href: string;
  cta: string;
  asChild?: boolean;
}

const BentoCard = (props: BentoCardProps) => {
  const { name, className, Icon, description, href, cta, asChild } = props;
  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      key={name}
      className={cn(
        'col-span-auto group relative flex h-full flex-col justify-between overflow-hidden rounded-xl',
        // light styles
        'bg-white [box-shadow:0_0_0_1px_rgba(0,0,0,.03),0_2px_4px_rgba(0,0,0,.05),0_12px_24px_rgba(0,0,0,.05)]',
        // dark styles
        'transform-gpu dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)]',
        className,
      )}
    >
      <div className="absolute hidden h-full w-full lg:static lg:block">
        <GridBgAnimation
          numSquares={30}
          maxOpacity={0.5}
          duration={3}
          repeatDelay={1}
          className={cn(
            'opacity-45 [mask-image:radial-gradient(300px_circle_at_center,white,transparent)]',
            'inset-x-0 inset-y-[-30%] h-[200%] skew-y-12',
          )}
        />
      </div>

      <div className="pointer-events-none relative z-10 flex transform-gpu flex-col gap-1 p-6 transition-all duration-300 lg:group-hover:-translate-y-10">
        {Icon && (
          <Icon className="z-10 mb-2 h-8 w-8 origin-left transform-gpu text-neutral-700 transition-all duration-300 ease-in-out dark:text-white lg:h-10 lg:w-10 lg:group-hover:scale-75" />
        )}

        <h2 className="z-10 font-semibold text-neutral-700 dark:text-neutral-300 md:text-lg lg:text-xl">{name}</h2>

        <p className="z-10 max-w-lg text-muted-foreground md:text-sm">{description}</p>

        <div className="absolute inset-x-0 bottom-0 z-0 hidden h-full bg-gradient-to-b from-transparent to-white to-50% dark:to-black sm:to-100% lg:block"></div>
      </div>

      <div
        className={cn(
          'pointer-events-none flex w-full transform-gpu flex-row items-center bg-white p-4 transition-all duration-300 dark:bg-black lg:absolute lg:bottom-0 lg:translate-y-10 lg:group-hover:translate-y-0',
        )}
      >
        <Button variant="link" size="sm" className="pointer-events-auto">
          <Link href={href} className="flex items-center">
            {cta}
            <ArrowRightIcon className="ml-2 h-4 w-4" />
          </Link>
        </Button>
      </div>

      <div className="pointer-events-none absolute inset-0 transform-gpu transition-all duration-300 lg:group-hover:dark:bg-neutral-800/10" />
    </Comp>
  );
};

export { BentoCard, BentoGrid };
